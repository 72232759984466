import React from "react";

CenterContentHome.Header = ({ children }) => {
  return <h1>{children}</h1>;
};

export default function CenterContentHome({ children }) {
  return (
    <section className="page_header_center">
      <div className="container">
        {children}
      </div>
    </section>
  );
}
