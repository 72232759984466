import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const cardOne = require("../../assets/images/homepage/group1.png");
const cardTwo = require("../../assets/images/homepage/group4.png");

const topImage = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_header.png");
export const icon1 = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_sec_3_icon.png");

const section_one = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_sec_1.png");
const section_two = require("../../assets/images/10-pages/hr_support_ind_3/hrsup_ind_3_sec_2.png");
const section_three = require("../../assets/images/automate_usp/marketplace_img.png");

const featureContent = [
  {
    icon: icon1,
    header: "Lean HR budgets. Scale with Workativ",
    content:
      "With Workativ, companies can save costs from day one. No upfront setup fee, no installations. Workativ can deliver ROI from the get-go.",

    image: section_one,
  },
  {
    icon: icon2,
    header: "Focus on employee development",
    content:
      "HR resources can really bog down on servicing day-to-day requests, consuming valuable time, and efforts. Free up HR’s valuable time to focus on employee development.",
    image: section_two,
  },
  {
    icon: icon3,
    header: "Modern HR engagement centered around messaging",
    content:
      "Be rid of logging in and switching between multiple apps constantly for employees to take care of their HR-related queries. Connect Workativ with tools like Slack, Microsoft Teams, Zenefits, and several others to deliver support. As simple as that.",
    image: section_three,
  },
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="HR Chatbot Powered by Conversational AI for better HR productivity | Workativ Assistant"
        description="Give employees a HR Chatbot and free up hr. staff from repetitive queries and boost their productivity. HR can focus on high-value tasks that require a human touch."
        keywords={[
          "Workativ  trial",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
        ogTitle="HR Chatbot Powered by Conversational AI for better HR productivity | Workativ Assistant"
        ogDescription="Give employees a HR Chatbot and free up hr. staff from repetitive queries and boost their productivity. HR can focus on high-value tasks that require a human touch.`"
      />
      <Container>
        <Layout backgroundColor={"bg_hr_suppport_three"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_hr_suppport_three"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Free up more time for your HR
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Save HR from repetitive queries to boost productivity. Free them
                to prioritize high-value employee engagement that truly requires
                a human touch.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              Our chatbot helps reduce HR staff burnout and attrition
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.header}
              >
                <LeftImageWithContent.HeaderIcon>
                  <div className="icon-top-img">
                    <img src={feature.icon} alt="icon" />{" "}
                  </div>
                </LeftImageWithContent.HeaderIcon>

                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                </LeftImageWithContent.SubHeader>
              </LeftImageWithContent>
            );
          })}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
